import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/(web)/page.tsx\",\"import\":\"Knewave\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-heading\",\"display\":\"swap\",\"weight\":[\"400\"]}],\"variableName\":\"fontHeading\"}");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/caculator.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/golden-cloud.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/guard.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/kyupad-heart-space.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/kyupad-meow-space.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/kyupad-section1.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/kyupad-section2.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/kyupad-section3.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/last-decorator.svg");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/meow-spacing.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/multi-spacing.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/multi-system.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/nft-pass.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/tropical.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/my-space/cat-right.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/animation/bottom-top.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/animation/lazy-animation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MotionDiv"] */ "/app/src/components/common/animation/motion-animation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/animation/scale.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider","Tooltip","TooltipTrigger","TooltipContent"] */ "/app/src/components/common/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/features/landing/choose-winners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/features/landing/ininative-system.tsx");
